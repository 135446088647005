<template>
  <section class="edit-category">
    
    <div class="row" v-if="payment">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-title">
            <div class="row">
              <div class="col-12 ">
                <h3 class="page-title float-left">Payment Modes</h3>

                <router-link class="float-right" to="/settings/payment-list">
                  <i class="mdi mdi-window-close font-size-18 text-dark"></i>
                </router-link>

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <form class="pt-2" @submit.prevent="editPaymentMode">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="card ">
                          <div class=" ">
                            <div class="form-group  mb-3">
                              <label class=" fs-12 p-0 m-0 col-form-label" for="name">Name
                              </label>
                              <div class="form-group">
                                <input type="text" v-model="payment.name" class="form-control" />
                              </div>
                            </div>
                            <div class="form-group  mb-3">
                              <label class=" m-0 p-0 fs-12 col-form-label" for="name">Description </label>

                              <div class="form-group">
                                <textarea class="form-control" rows="3" v-model="payment.description"></textarea>
                              </div>

                            </div>
                            <div>
                              <div class="form-group   mb-3">
                                <label class=" fs-12  p-0 m-0  col-form-label" for="name">Image
                                </label>
                                <div class="form-group">
                                  <div class="form-group">
                                    <vue-dropzone ref="icon" id="icon " :options="dropzoneOptions" :useCustomSlot="true"
                                      v-on:vdropzone-success="success" @vdropzone-sending="sendImages">
                                      <div class="dropzone-custom-content">
                                        <div v-if="info">
                                          <div class="image-size-dropzone">
                                            <img :src="`${imageApiURL}/${info.path}`" class="show-img img-fluid" />
                                          </div>
                                        </div>
                                        <div v-if="!info" class="pt-3">
                                          <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43"
                                            viewBox="0 0 24 24" fill="transparent" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"
                                            color="">
                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                            <polyline points="17 8 12 3 7 8"></polyline>
                                            <line x1="12" y1="3" x2="12" y2="15"></line>
                                          </svg>
                                        </div>
                                        <div class="subtitle pt-1 pb-0">
                                          <span> Drop File or </span>
                                          <span style="color: #eb2027"> Browse</span>
                                        </div>
                                      </div>
                                    </vue-dropzone>
                                  </div>
                                </div>

                              </div>
                            </div>
                            <div class="form-group  ">
                              <label for=""> Status </label>
                              <div class="form-group  ">
                                <toggle-button v-model="payment.active" :labels="{
                                  checked: 'true',
                                  unchecked: 'false',
                                }" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>




                      <!-- end col-->
                      <div class="col-sm-12">
                        <button class="btn btn btn-add btn-primary float-right">
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
const mediaApiURL = process.env.VUE_APP_MEDIA_SERVICE;
const mediaApi = process.env.VUE_APP_MEDIA_SERVICE;
const imageApiURL = process.env.VUE_APP_IMAGE_URL;
import { setingsApi } from "../../../api";

export default {
  name: "edit-payment-modes",
  props: {
    edit: {
      active: Boolean,
      name: String,
      description: String,
      image_id: Number,
    },
  },
  components: {
    
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      payment: this.edit,
      
      dropzoneOptions: {
        url: `${mediaApiURL}/file/upload`,
        maxFilesize: 10,
      },
      info: "",
      imageApiURL,
    };
  },
  mounted() {
    this.paymentModeById();
  },
  methods: {
    async paymentModeById() {
      
      const { data } = await setingsApi.paymentModeById(this.$route.params.id);
      let payments = data.data.data;
      payments.forEach((val) => {
        this.payment = val;
        if (this.payment.image_id) {
          this.paymentIconById();
        }
        if (this.payment.active == 1) {
          this.payment.active = true;
        } else {
          this.payment.active = false;
        }
      });
      
    },
    paymentIconById() {
      
      axios
        .get(`${mediaApi}/files/${this.payment.image_id}`)
        .then((response) => {
          this.info = response.data.data[0];
          
        });
    },
    sendImages(file, xhr, formData) {
      let pages = "pages";
      xhr.setRequestHeader("Header", "");
      formData.append("folder", pages);
    },
    success(file, response) {
      this.payment.image_id = response.data.id;
      if (response.status == "success") {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },
    async editPaymentMode() {
      if (this.payment.active === true) {
        this.payment.active = 1;
      } else {
        this.payment.active = 0;
      }

      for (var key in this.payment) {
        if (this.payment.hasOwnProperty(key)) {
          var val = this.payment[key];
          if (val === null) {
            delete this.payment[key];
          }
        }
      }
      
      const data = await setingsApi.updatePaymentMode(this.$route.params.id, {
        ...this.payment,
      });
      
      this.$router.push("/settings/payment-list");
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 0.5rem;
}

.dropzone {
  padding: 8px 20px !important;
}
</style>
